import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, map, Subject, throwError } from 'rxjs';
import { defaultConectionV1, usersApi } from '../constants/inclub-ribera-admin.constant';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class GeneralService {

  private storage: Storage | undefined;
  private _isHiddenActivitySidebar = false;
  private _hiddenActivitySidebar = new BehaviorSubject<boolean>(false);
  private refreshNeeded$ = new Subject<void>();
  URL_API = `${defaultConectionV1}`;
  URL_API_USERS = `${usersApi}`;

  constructor(private http: HttpClient) {
    this.storage = this.getStorage();
  }


  private getStorage(): Storage | undefined {
    try {
      if (typeof window !== 'undefined' && window.sessionStorage) {
        return window.sessionStorage;
      } else {
        return undefined;
      }
    } catch (e) {
      console.warn('sessionStorage is not available:', e);
      return undefined;
    }
  }

  getStatusActivitySidebar() {
    return this._hiddenActivitySidebar.asObservable();
  }

  toggleActivitySidebar() {
    this._isHiddenActivitySidebar = !this._isHiddenActivitySidebar;
    this._hiddenActivitySidebar.next(this._isHiddenActivitySidebar);
  }

  get<T = any>(key: string): string | null | T {
    if (this.storage?.getItem(key)) {
      try {
        return JSON.parse( this.storage.getItem(key) as string) as T
      } catch (e) {
        return this.storage.getItem( key );
      }
    } else {
      return null
    }
  }

  set<T = any>(key: string, value: T): void {
    if (this.storage) {
      this.storage.setItem(key, JSON.stringify(value));
    }
  }

  get refreshNeeded() {
    return this.refreshNeeded$.asObservable();
  }

  requestRefresh() {
    this.refreshNeeded$.next();
  }
  private getHeaders(): HttpHeaders {
    const token = this.storage?.getItem('token');
    return new HttpHeaders({
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    });
  }
  private handleError(error: HttpErrorResponse) {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }
  getState(module: string, params?: { [key: string]: any }, userId?: number) {
    const url = new URL(`${this.URL_API}/${module}`);

    // Añadir parámetros a la URL si existen
    if (params) {
      Object.keys(params).forEach((key) =>
        url.searchParams.append(key, params[key])
      );
    }

    const headers = this.getHeaders();
    return this.http
      .get(url.toString(), { headers })
      .pipe(catchError(this.handleError));
  }
  deleteData(module: string, params?: any) {
    const url = `${this.URL_API}/${module}`;
    return this.http.delete(url, params).pipe(
      map((resp: any) => resp),
      catchError(this.handleError)
    );
  }
  getCalendarDate(roomOfferId: number) {
    const url = `${this.URL_API}/booking/calendarDate?roomOfferId=${roomOfferId}`;
    const headers = this.getHeaders();
    return this.http.get(url, { headers }).pipe(catchError(this.handleError));
  }
  postDataBooking(module: string, params: any) {
    const url = `${this.URL_API}/${module}`;
    const headers = this.getHeaders();
    return this.http.post(url, params, { headers }).pipe(
      map((resp: any) => resp),
      catchError(this.handleError)
    );
  }
  
  deleteById(module: string, id: any) {
    const url = `${this.URL_API}/${module}/${id}`;
    return this.http.delete(url).pipe(
      map((resp: any) => resp),
      catchError(this.handleError)
    );
  }

  getApiUsersAll() {
    const url = `${this.URL_API_USERS}`;
    return this.http.get(url).pipe(catchError(this.handleError));
  }
  deleteBooking(id: number) {
    return this.http.delete(`${this.URL_API}/booking/${id}`);
  }
  getData(module: string, id?: number, path?: string) {
    let url = `${this.URL_API}/${module}`;
    if (id !== undefined) {
      url += `/${id}`;
    }
    if (path) {
      url += `/${path}`;
    }
    return this.http.get(url).pipe(catchError(this.handleError));
  }
  postData(module: any, params: any) {
    const url = `${this.URL_API}/${module}`;
    return this.http.post(url, params).pipe(
      map((resp: any) => resp),
      catchError(this.handleError)
    );
  }
  postDataBookingByReceptionist(module: string, params: any) {
    const url = `${this.URL_API}/${module}`;
    const headers = this.getHeaders();
    return this.http.post(url, params, { headers }).pipe(
      map((resp: any) => resp),
      catchError(this.handleError)
    );
  }
  getKey<T = any>(key: string): string | null | T {
    if (this.storage?.getItem(key)) {
      try {
        return JSON.parse(this.storage.getItem(key) as string) as T;
      } catch (e) {
        return this.storage.getItem(key);
      }
    } else {
      return null;
    }
  }

  getStateByReceptionist(
    module: string,
    params?: { [key: string]: any },
    userId?: number
  ) {
    const url = new URL(`${this.URL_API}/${module}`);

    // Añadir parámetros a la URL si existen
    if (params) {
      Object.keys(params).forEach((key) =>
        url.searchParams.append(key, params[key])
      );
    }

    const headers = this.getHeaders();
    return this.http
      .get(url.toString(), { headers })
      .pipe(catchError(this.handleError));
  }
}
